<template>
  <div class="friend-list-tab mt-2">
    <div class="iq-card-body p-0">
      <div class="row" v-if="followers.length">
        <div v-for="(follower,index1) of followers" :key="index1" class="mt-5 col-12 col-sm-6 col-md-4 col-lg-3">
          <iq-card className="iq-card-block iq-card-stretch side_border">
            <template v-slot:body>
              <div class="iq-badges text-left" v-on:click="viewFollower(follower.user_id)" style="cursor: pointer;">
                <div class="badges-icon">
                  <b-avatar style="border-radius: 100%!important;" class="avatar-80 rounded" v-if="follower.image_thumbnail">
                    <img :src="follower.image_thumbnail" class="avatar-80 rounded" alt="follwerImages">
                  </b-avatar>
                    <b-avatar v-else class="avatar-80" :variant="getBgColorForLetter(follower.user_name)" :text="getFirstLetterOfAString(follower.user_name)" >
                  </b-avatar>
                </div>
                <h5 class="mb-2 title twoLineOnly">
                  {{follower.user_name && follower.user_name.includes(" ") ? follower.user_name.split(' ')[0] : follower.user_name}}
                </h5>
              </div>
              <span class="mr-2 font-weight-normal pull-right" variant="primary">
                <span v-if="follower.class">
                  {{follower.class | addSuffixToClass}} Class,
                </span>
                <span v-else>
                   -
                </span>
                {{follower.org_city}}
              </span>
            </template>
          </iq-card>
        </div>
      </div>
      <div v-else class="text-center">
        There is no follower
      </div>
      <b-col lg="12" class="text-center pt-3">
        <div v-if="!allLoaded" v-infinite-scroll="followList" :infinite-scroll-disabled="busy" infinite-scroll-distance="10">
          <b-spinner variant="primary" label="Spinning" >
          </b-spinner>
        </div>
      </b-col>
    </div>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>

</template>
<script>
import { socialvue } from "../config/pluginInit"
import { Follows } from "../FackApi/api/follow"
import Utility from "../Utils/utility"

export default {
  name: "FollowerList",
  components: {},
  props: {
    propFireApi: {
      type: Boolean,
      required: true,
      default: false
    },
    propModuleId: {
      type: String,
      required: true,
      default: ""
    },
    propFollowedByCurrUser: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data () {
    return {
      followers: [],
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Follow Response",
      currentPage: 0,
      allLoaded: false,
      busy: false
    }
  },
  mounted () {
    socialvue.index()
  },
  watch: {
    async propFireApi () {
      if (this.propFireApi) {
        await this.followList()
      }
    },
    propFollowedByCurrUser (newVal, oldVal) {
      let currUserId = this.userData.user_id
      let currUserName = this.userData.user_name
      if (!newVal && oldVal) {
        // unfollow  by current user
        const findIndex = this.followers.findIndex(user => user.user_id === currUserId)
        if (findIndex >= 0) {
          this.followers.splice(findIndex, 1)
        }
      }
      else if (newVal && !oldVal) {
        // follow by curr user
        const findIndex = this.followers.findIndex(user => user.user_id === currUserId)
        if (findIndex < 0 && this.followers.length > 0) {
          let newFollowerObj = {
            module_id: this.$route.params.club_id,
            user_id: currUserId,
            user_name: currUserName
          }
          this.followers.unshift(newFollowerObj)
        }
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    /**
     * View Follower On click
     */
    viewFollower (userId) {
      this.$router.push(`/user/${userId}`)
    },
    /**
     * getBgColorForLetter
     */
    getBgColorForLetter (name) {
      let firstLetter = this.getFirstLetterOfAString(name)
      return Utility.getBgColorForLetter(this, firstLetter)
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * followList ()
     */
    async followList () {
      this.busy = true
      if (this.allLoaded) {
        return
      }
      try {
        this.currentPage += 1
        let followListResp = await Follows.followList(this, this.propModuleId, this.currentPage)
        if (followListResp && followListResp.resp_status) {
          this.busy = false
          this.followers = [...this.followers, ...followListResp.resp_data.data]
        }
        else {
          this.busy = false
          this.allLoaded = true
        }
      }
      catch (err) {
        console.error("Exception occurred at followList () and Exception :", err.message)
      }
      finally {
        this.busy = false
      }
    }
  }
}
</script>
